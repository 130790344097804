<template>
  <div>
    <my-dialog
      :title="$t('questions.setting')"
      v-model="openModal"
      :isLoading="isLoading"
    >
      <template v-slot:content>
        <v-row
        class="d-flex align-center justify-center justify-space-around"
        >
          <v-col
            cols="8"
            >
            <div>
              <v-select
                v-model="form.type"
                :items="types"
                class="white-text mb-0"
                :label="$t('questions.selectType')"
              />
            </div>
            <!-- <div>
              <cloudinary
                v-if="viewSettingByType('slidBackgroundMedia')"
                :publicId="form.slidBackgroundMedia.src"
                :buttonText="$t('questions.slidBackgroundMedia')"
                @save="v => saveMedia(v, 'slidBackgroundMedia')"
                @loading="loading"
                :format="form.slidBackgroundMedia.format"
                :deleteText="$t('generics.remove')"
              />
            </div> -->
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="6" v-if="viewSettingByType('exitGame')">
            <v-checkbox
              v-model="form.exitGame"
              :label="$t('מי שלא עונה נכון יצא מהמשחק')"
              rows="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" v-if="viewSettingByType('correctlyAnsweredBefore')">
            <v-checkbox
              v-model="form.correctlyAnsweredBefore"
              :label="$t('מי שענה נכון על השאלה שלפניה יכול לענות')"
              rows="1"
            ></v-checkbox>
          </v-col>

          <v-col cols="6" v-if="viewSettingByType('firstClicker')">
            <v-checkbox
              v-model="form.firstClicker"
              :label="$t('רק הלוחץ ראשון יוכל לענות')"
              rows="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" v-if="viewSettingByType('answerIsSequenceClicks')">
            <v-checkbox
              v-model="form.answerIsSequenceClicks"
              :label="$t('שאלה שהתשובה תהיה רצף לחיצות לפי סדר מסוים')"
              rows="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" v-if="viewSettingByType('allowChangeVote')">
            <v-checkbox
              v-model="form.allowChangeVote"
              :label="$t('אפשר לשנות את ההצבעה אחרי שכבר הצבעת')"
              rows="1"
            ></v-checkbox>
          </v-col>

          <v-col cols="6" v-if="viewSettingByType('showInLoop')">
            <v-checkbox
              v-model="form.showInLoop"
              :label="$t('הצג בלופ')"
              rows="1"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" v-if="viewSettingByType('fullscreen')">
            <v-checkbox
              v-model="form.fullscreen"
              :label="$t('מדיה על כל המסך')"
              rows="1"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            class="d-flex"
            v-if="viewSettingByType('scoringReduction')"
          >
            <v-row>
              <v-col cols="4" class="d-flex">
                <v-checkbox
                  v-model="form.scoringReduction.active"
                  :label="$t('הפחתת ניקוד לאחר זמן')"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="3"
                class="d-flex"
                v-if="form.scoringReduction.active"
              >
                <v-text-field
                  v-model="form.scoringReduction.seconds"
                  :label="$t('שניות')"
                  type="number"
                  min="1"
                  :error-messages="error.seconds"
                  :rules="[v => v > 0 || $t('errors.validate.validNumber')]"
                />
              </v-col>
              <v-col
                cols="3"
                class="d-flex"
                v-if="form.scoringReduction.active"
              >
                <v-text-field
                  v-model="form.scoringReduction.score"
                  :label="$t('ניקוד')"
                  type="number"
                  min="1"
                  :error-messages="error.score"
                  :rules="[v => v > 0 || $t('errors.validate.validNumber')]"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="viewSettingByType('playAfterClicking')">
            <v-radio-group v-model="radioVote">
              <v-radio
                :key="'slideStartVoting'"
                :label="$t('הצבעה מתחילת עליית השאלה')"
                :value="true"
              ></v-radio>
              <v-radio
                :key="'playAfterClicking'"
                :label="$t('הפעלה אחרי לחיצה')"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-row v-if="viewSettingByType('skipAfterClick')">
              <v-col cols="4" class="d-flex">
                <v-radio-group v-model="radioSkip">
                  <v-radio
                    :key="'skipAfterClick'"
                    :label="$t('מעבר שקופיות אחרי לחיצה')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :key="'automaticSkip'"
                    :label="$t('מעבר שקופיות אטומטי')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="4" class="d-flex" v-if="form.automaticSkip.active">
                <v-text-field
                  v-model="form.automaticSkip.seconds"
                  :label="$t('שניות למעבר לשקופית הבאה')"
                  type="number"
                  min="1"
                  max="120"
                  :rules="[
                    v =>
                      (v > 0 && v < 121) || $t('errors.validate.validNumber'),
                  ]"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row> -->
      </template>
      <template v-slot:actions>
        <v-btn
          text
          color="primary"
          v-text="$t('generics.save')"
          @click="submit"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import MyDialog from './MyDialog.vue'
// import Cloudinary from './buttons/Cloudinary.vue'
import {
  mediaStructure,
  surveyStructure,
  triviaStructure,
  ans_imageStructure,
} from '@/data/data structure/index.js'
export default {
  name: 'SlideSetting',
  components: {
    MyDialog,
    //  Cloudinary
    },
  props: {
    value: Boolean,
    slideSetting: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    isLoading: false,
    radioVote: true,
    radioSkip: true,
    form: {
      type: 'trivia',
      allowChangeVote: false,
      slideStartVoting: true,
      playAfterClicking: false,
      showInLoop: false,
      exitGame: false,
      correctlyAnsweredBefore: false,
      firstClicker: false,
      answerIsSequenceClicks: false,
      fullscreen: false,
      scoringReduction: {
        active: false,
        seconds: '',
        score: '',
      },
      automaticSkip: {
        active: false,
        seconds: '',
      },
      slidBackgroundMedia: {
        src: '',
        format: '',
      },
    },
    error: {},
    automaticSkip: false,
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set() {
        this.$emit('onClose')
      },
    },
    types() {
      return this.$store.getters.questionsTypes?.length
        ? this.$store.getters.questionsTypes
        : []
    },
    dataStructure() {
      switch (this.form.type) {
        case 'media':
          return mediaStructure.setting
        case 'survey':
          return surveyStructure.setting
        case 'trivia':
          return triviaStructure.setting
        case 'ans_images':
          return ans_imageStructure.setting
        default:
          return triviaStructure.setting
      }
    },
  },
  methods: {
    viewSettingByType(name) {
      return this.dataStructure?.fieldsList?.includes(name)
    },
    removeFieldsFromSetting() {
      for (const [key] of Object.entries(this.form)) {
        if (!this.dataStructure?.fieldsList?.includes(key)) {
          delete this.form[key]
        }
      }
    },
    loading(v) {
      this.isLoading = v
    },
    submit() {
      if (!this.validateForm()) return
      this.removeFieldsFromSetting()
      this.$emit('save', this.form)
    },
    saveMedia(value, name) {
      this.form[name].src = value.public_id
      this.form[name].format = value.format
    },
    validateForm() {
      const {
        scoringReduction: { active, seconds, score },
      } = this.form
      if (active) {
        const error = 'לא יכול להיות ריק'
        // this.error["seconds"] = this.$t("הכנס זמן");
        this.error = {
          seconds: !seconds ? error : '',
          score: !score ? error : '',
        }
        return seconds && score
      }
      //TODO: add form validations here
      return true
    },
    createErr(msg) {
      this.err = msg
      setTimeout(() => {
        this.err = null
      }, 2000)
      return
    },
  },
  mounted() {
    this.form = { ...this.form, ..._.cloneDeep(this.slideSetting) }
    if (this.form?.automaticSkip?.active) {
      this.radioSkip = false
    }
    if (this.form?.playAfterClicking) {
      this.radioVote = false
    }
  },
  watch: {
    'form.automaticSkip.active'() {
      if (!this.form.automaticSkip.active) {
        this.form.automaticSkip.seconds = ''
      }
    },
    radioVote() {
      if (this.radioVote) {
        this.form.slideStartVoting = true
        this.form.playAfterClicking = false
      } else {
        this.form.slideStartVoting = false
        this.form.playAfterClicking = true
      }
    },
    radioSkip() {
      if (this.radioSkip) {
        this.form.skipAfterClick = true
        this.form.automaticSkip.active = false
      } else {
        this.form.skipAfterClick = false
        this.form.automaticSkip.active = true
      }
    },
  },
}
</script>
