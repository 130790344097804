<template>
  <div>
    <v-row v-if="publicId" class="my-5">
      <v-col class="image-show">
        <div>{{ buttonText }}:</div>
        <cld-video
          v-if="format === 'mp4'"
          class="center-all"
          :publicId="publicId"
          :width="width"
          crop="scale"
        />
        <play-audio-cloudinary
          v-else-if="format === 'mp3'"
          :publicId="publicId"
        />
        <cld-image
          v-else
          class="center-all"
          :publicId="publicId"
          :width="width"
          crop="scale"
        />
      </v-col>
    </v-row>
    <v-row class="center-all my-5">
      <v-btn
        v-if="!publicId"
        @click.native="uploadFiles"
        :color="buttonColor"
        :dark="buttonDark"
      >
        {{ buttonText }}
        <v-icon
          v-if="buttonIcon"
          :right="buttonText ? true : false"
          aria-hidden="true"
        >
          {{ buttonIcon }}
        </v-icon>
      </v-btn>
      <div v-if="publicId">
        <v-btn
          class="ma-0"
          dark
          small
          color="error"
          @click="deleteImageFromCloud"
        >
          {{ deleteText }}
        </v-btn>
      </div>
    </v-row>
  </div>
</template>
<script
  src="https://upload-widget.cloudinary.com/global/all.js"
  type="text/javascript"
></script>
<script>
import SwalMixins from '@/mixins/swal'
import PlayAudioCloudinary from '../PlayAudioCloudinary.vue'

export default {
  components: {
    PlayAudioCloudinary,
  },
  props: {
    publicId: {
      type: String,
    },
    format: {
      type: String,
    },
    // Cloudinary upload preset

    width: {
      type: Number,
      default: 100,
    },

    // Upload button color (default = gray)
    buttonColor: {
      type: String,
      default: 'green',
    },
    // Upload button dark variant (makes text white)
    buttonDark: {
      type: Boolean,
      default: true,
    },
    // Upload button icon on the right
    buttonIcon: {
      type: String,
      default: 'mdi-cloud-upload',
    },
    // Upload button text
    buttonText: {
      type: String,
      default: 'add image',
    },
    // Delete button text
    deleteText: {
      type: String,
      default: 'Delete',
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
    onlySound: {
      type: Boolean,
      default: false,
    },
    onlyVideo: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SwalMixins],
  computed: {
    cloudName() {
      return this.$store.getters.cloudName
    },
    uploadPreset() {
      return this.$store.getters.uploadPreset
    },
    folderName() {
      return `${this.$store.getters.game.cloudinaryFolder}/assets`
    },
    gameId() {
      return this.$store.getters.game?._id
    },
    formatApproved() {
      let formats = []
      const imageFormats = ['jpg', 'jpeg', 'png']
      const soundFormat = ['mp3']
      const videoFormat = ['mp4']
      if (this.onlyVideo) {
        formats = formats.concat(videoFormat)
      }
      if (this.onlySound) {
        formats = formats.concat(soundFormat)
      }
      if (this.onlyImage) {
        formats = formats.concat(imageFormats)
      }
      if (!this.onlyVideo && !this.onlySound && !this.onlyImage) {
        formats = formats.concat(videoFormat, soundFormat, imageFormats)
      }
      return formats
    },
    formatsToString() {
      return this.formatApproved.join('/')
    },
  },
  methods: {
    // Upload the image
    async uploadFiles() {
      this.$emit('loading', true)
      this.$store.commit('loading/active', true)
      try {
        var myWidget = window.cloudinary.createUploadWidget(
          {
            showPoweredBy: false,
            clientAllowedFormats: ['mp3', 'mp4', 'jpg', 'jpeg', 'png'],
            maxImageWidth: 1920,
            maxImageHeight: 1080,
            // cropping: true,
            cloudName: this.cloudName,
            uploadPreset: this.uploadPreset,
            folder: this.folderName,
            multiple: false,
            max_files: 2,
            // 1mb * 50 = 50mb
            maxFileSize: 1000000 * 30,
            language: 'en',
            text: {
              en: {
                menu: {
                  files: 'הקבצים שלי',
                  web: 'כתובת אינטרנט',
                  camera: 'מצלמה',
                },
                queue: {
                  title: '',
                  done: 'הסתיים',
                },

                // גודל מירבי של קובץ לא יעלה על 25 מ"ב
                //  \n
                or: `
                 ${this.formatsToString}

                   :שימו לב!
                ניתן להעלות רק קבצים בפורמט
                  `,
                local: {
                  browse: 'לחץ כאן',
                  dd_title_single: 'גרור לפה את התמונה או את הוידאו',
                  dd_title_multi: 'גרור לפה את התמונה או את הוידאו',
                },
              },
            },

            styles: {
              palette: {
                // window: "#FFF",
                // windowBorder: "#90A0B3",
                // tabIcon: "#0E2F5A",
                // menuIcons: "#5A616A",
                textDark: '#F44235',
                // textLight: "#FFFFFF",
                // link: "#0078FF",
                // action: "#FF620C",
                // inactiveTabIcon: "#0E2F5A",
                // error: "#F44235",
                // inProgress: "#0078FF",
                // complete: "#20B832",
                // sourceBg: "#E4EBF1",
              },
              // frame: {
              //   background: "#0E2F5B99",
              // },
              // fonts: {
              //   "'Cute Font', cursive":
              //     "https://fonts.googleapis.com/css?family=Cute+Font",
              // },
            },
            preBatch: (cb, data) => {
              if (data?.files[0]?.name.includes('~')) {
                this.timeoutSwal({
                  title: this.$t('errors.tildeUpload'),
                  icon: 'error',
                })
                return cb({ cancel: true })
              }
              let format = data?.files[0]?.name?.split('.')[1].toLowerCase()
              if (!this.formatApproved.includes(format)) {
                this.timeoutSwal({
                  title:
                    this.$t('errors.format') +
                    ' ' +
                    this.formatsToString +
                    ' את/ה העלת פורמט ' +
                    format,
                  icon: 'error',
                  timer: 2500,
                })
                return cb({ cancel: true })
              }
              return cb()
            },
          },
          (error, result) => {
            if (!error && result && result.event === 'success') {
              this.$emit('save', result.info)
              this.$emit('saveAndUpdate')
            } else if (
              error?.statusText?.toLowerCase() === 'file format not allowed'
            ) {
              this.timeoutSwal({
                title: this.$t('errors.format') + ' ' + this.formatsToString,
                icon: 'error',
                timer: 2500,
              })
            } else {
              console.log(error)
            }
            this.$emit('loading', false)
            this.$store.commit('loading/active', false)
          }
        )
        await myWidget.open()
      } catch (e) {
        console.log(e)
        this.timeoutSwal({ title: this.$t('images.saveError') })
        this.$store.commit('loading/active', false)
      }
    },
    async deleteImageFromCloud() {
      try {
        this.$emit('save', { public_id: '', format: '' })
        this.$emit('saveAndUpdate')
        // await this.$store.dispatch("image/destroy", {
        //   public_id: this.publicId,
        //   type: this.format,
        // });
        this.timeoutSwal({ icon: 'success', title: this.$t('images.delete') })
      } catch (e) {
        console.log(e)
        this.timeoutSwal({ title: this.$t('images.deleteError') })
      }
    },
  },
}
</script>
<style scoped lang="css">
.image-show {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
