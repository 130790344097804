<template>
  <v-container>
    <v-row class="center-all my-5">
      <h1>{{ $t("games.name") }} : {{ gameName }}</h1>
    </v-row>
    <setting />
    <slides-list />
  </v-container>
</template>

<script>
import SlidesList from "@/components/SlidesList.vue";
import Setting from "@/components/Setting";
import SwalMixins from "@/mixins/swal";

export default {
  components: {
    SlidesList,
    Setting,
  },
  name: "CreateGame",
  mixins: [SwalMixins],
  data() {
    return {
      notChange: true,
      currentIndex: 0,
      search: "",
    };
  },
  computed: {
    game() {
      return this.$store.getters.game;
    },
    gameName() {
      return this.game?.name;
    },
  },
  methods: {
    clearData() {
      this.$store.commit("game/clearData");
      this.$store.commit("questions/clearData");
    },
  },
  async beforeMount() {
    try {
      this.clearData();
      this.$store.commit("loading/active", true);
      this.$store.commit("title/set", this.$t(`route.${this.$route.name}`));
      if (!this.game) {
        await this.$store.dispatch("game/show", this.$route.params.id);
      }
      if (!this.game) {
        this.$router.push({ name: "UserDashboard" });
      }
      this.$store.commit("loading/active", false);
    } catch (e) {
      console.log(e);
    }
  },
  destroyed() {
    this.clearData();
  },
};
</script>
<style lang="scss" scoped>
.categories-container {
  width: 500px;
  margin: 0 auto;
}
</style>
