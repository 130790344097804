<template>
  <v-app-bar flat app>
    <v-row>
      <v-col cols="1 ">
        <div class="d-flex justify-start" v-if="!isHomePage">
          <v-icon color="primary" @click="goHomePage">mdi-arrow-right</v-icon>
        </div>
        <div class="d-flex justify-start" v-else-if="!isAdmin">
          <v-icon color="primary" @click="showUserDialog"
            >mdi-account-circle</v-icon
          >
        </div>
      </v-col>
      <v-col cols="10" class="d-flex justify-center align-center">
        <!-- <v-toolbar-title> -->
        <v-toolbar-title
          class="primary--text h3 justify-self-start"
          v-text="$store.getters.title"
        />
        <!-- </v-toolbar-title> -->
      </v-col>
      <v-col cols="1">
        <div class="d-flex justify-end mx-2">
          <v-icon color="red" @click="logout" v-text="'mdi-power'" />
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import SwalMixins from "@/mixins/swal";

export default {
  name: "Navbar",
  mixins: [SwalMixins],
  data() {
    return {
      userDialog: false,
    };
  },
  computed: {
    isHomePage() {
      return this.$route.name.includes("Dashboard");
    },
    isAdmin() {
      return this.$store.getters.adminLoggedIn;
    },
  },
  methods: {
    goHomePage() {
      this.$router.push({
        name: this.isAdmin ? "AdminDashboard" : "UserDashboard",
      });
    },
    showUserDialog() {
      return this.$store.commit("auth/userDetailsDialog", !this.userDialog);
    },
    async logout() {
      let { isConfirmed } = await this.createSwal({
        title: `${this.$t("auth.logout")}?`,
        icon: "question",
        confirmColor: "red",
      });
      if (isConfirmed) await this.$store.dispatch("auth/logout");
    },
  },
};
</script>
