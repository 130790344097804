<template>
  <div class="home">
    <v-container class="form-container d-flex justify-center flex-column mt-12">
      <form @keyup.enter="submit">
        <v-row justify="center">
          <v-col md="8" cols="10">
            <v-text-field
              v-if="registerMod"
              v-model="fullname"
              label="שם מלא"
              :rules="[rules.nameRequired]"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="8" cols="10">
            <v-text-field
              v-model="email"
              label="אימייל"
              :rules="[rules.emailRequired, rules.validEmail]"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="8" cols="10">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              label="סיסמה"
              :type="show ? 'text' : 'password'"
              :rules="[rules.passRequired, rules.min]"
              @click:append="show = !show"
              minLength="6"
              required
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="d-flex justify-center">
            <v-btn
              @click="submit"
              text
              color="primary"
              :disabled="isValidForm"
              v-text="registerMod ? 'הרשמה' : 'התחברות'"
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col class="d-flex justify-center">
            <v-btn
              @click="toggleRegister"
              text
              color="primary"
              v-text="
                registerMod ? 'רשומים? התחברו עכשיו' : 'לא רשומים? הרשמו עכשיו'
              "
            />
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col
            @click="googleLogin"
            class="d-flex d-flex justify-center align-center"
          >
            <google-btn />
          </v-col>
        </v-row>
      </form>
      <v-alert
        v-if="isAlertMessage"
        outlined
        type="error"
        class="d-flex justify-center mt-5"
        >{{ this.error }}
      </v-alert>
      <div class="progress">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
          v-if="loading"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import GenericMixins from "@/mixins/generic";
import SwalMixins from "@/mixins/swal";
import GoogleBtn from "@/components/buttons/GoogleBtn.vue";
export default {
  components: { GoogleBtn },
  name: "Login",
  mixins: [GenericMixins, SwalMixins],
  data: () => {
    return {
      show: false,
      email: "",
      password: "",
      fullname: "",
      isAlertMessage: false,
      loading: false,
      registerMod: false,
    };
  },
  computed: {
    isValidForm() {
      return !(
        (this.registerMod ? this.fullname : true) &&
        this.email &&
        this.emailValidation(this.email) &&
        this.password &&
        this.password.length >= 6
      );
    },
    rules() {
      return {
        nameRequired: (v) => !!v || "נא להזין שם מלא",
        emailRequired: (v) => !!v || "נא להזין אימייל",
        validEmail: (v) => this.emailValidation(v) || "אימייל לא תקין",
        passRequired: (v) => !!v || "נא להזין סיסמה",
        min: (v) => v.length >= 6 || "6 תווים לפחות",
      };
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        if (!this.registerMod) {
          await this.$store.dispatch("auth/login", {
            email: this.email,
            password: this.password,
          });
          if (this.$store.getters.adminLoggedIn)
            this.$router.push({ name: "AdminDashboard" });
          else this.$router.push({ name: "UserDashboard" });
        } else {
          await this.$store.dispatch("auth/register", {
            email: this.email,
            password: this.password,
            fullname: this.fullname,
          });
          this.timeoutSwal({
            title: this.$t("auth.registerSuccess"),
            icon: "success",
            timer: 2500,
          });
          this.registerMod = false;
        }
      } catch (e) {
        console.log("No auth");
      }
      this.loading = false;
    },
    async googleLogin() {
      window.location.href = "/auth/google";
    },
    toggleRegister() {
      this.registerMod = !this.registerMod;
    },
  },
  mounted() {
    this.$store.commit("title/set", this.$t(`route.${this.$route.name}`));
  },
};
</script>
<style lang="scss" scoped>
.home {
  .progress {
    margin: 20% auto;
  }
}
</style>
