<template lang="">
  <v-overlay :opacity="0.8" :value="openModal">
    <v-card width="400" height="200" color="white">
      <v-app-bar flat color="white">
        <v-spacer />
        <v-btn icon @click="openModal = false" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text class="d-flex justify-center align-center">
        <v-btn color="primary" :href="link" class="mx-2">למשחק</v-btn>
        <v-btn color="primary lighten-1" class="mx-2" @click="copyLink">
          העתק קישור
        </v-btn>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
import SwalMixins from "@/mixins/swal";
export default {
  name: "ShowLink",
  props: { value: Boolean, link: String },
  mixins: [SwalMixins],

  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async copyLink() {
      navigator.clipboard.writeText(this.link);
      this.timeoutSwal({
        title: "הקישור הועתק בהצלחה!",
        icon: "success",
      });
    },
  },
};
</script>
<style lang=""></style>
