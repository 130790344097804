var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"container mt-16",attrs:{"max-width":"1200"}},[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"center-all",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.games,"search":_vm.search,"loading":_vm.loading,"items-per-page":7,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmailUser(item.user))+" ")]}},{key:"item.room",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":item.room,"type":"number","min":"1011","max":"5000","rules":[function (val) { return (+val  >= _vm.minRoomNumber && +val <= _vm.maxRoomNumber  || +val === 0 ) || ("0 מוחק את החדר. הטווח של החדרים צריך להיות בין " + _vm.minRoomNumber + " ל" + _vm.maxRoomNumber + "."); }],},on:{"change":function (v, e) { return _vm.saveRoom(v, e, item); }}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('games.duplicateGameTooltip'),"icon":'mdi-content-duplicate',"fab":true},on:{"action":function($event){return _vm.duplicate(item)}}}),_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('games.editGameTooltip'),"icon":'mdi-pencil',"fab":true},on:{"action":function($event){return _vm.editItem(item)}}}),_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('games.deleteGameTooltip'),"icon":'mdi-delete',"fab":true,"color":"error"},on:{"action":function($event){return _vm.deleteItem(item)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }