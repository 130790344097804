<template>
  <div>
    סאונד:
    <v-row>
      <v-col
        cols="4"
        class="d-flex justify-center align-center"
        v-for="name in soundList"
        :key="name"
      >
        <Cloudinary
          :publicId="form[name].src"
          :buttonText="$t(`setting.${name}`)"
          @save="(v) => saveMedia(v, name)"
          @loading="loading"
          :format="form[name].format"
          :deleteText="$t('generics.remove')"
          onlySound
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Cloudinary from "@/components/buttons/Cloudinary.vue";
export default {
  name: "setting-sound",
  props: { value: Object, soundList: Array },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    saveMedia(value, name) {
      this.form[name].src = value.public_id;
      this.form[name].format = value.format;
    },
    loading(v) {
      this.$emit("loading", v);
    },
  },
  components: { Cloudinary },
};
</script>
