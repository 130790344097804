<template>
  <v-row>
    <v-col lg="6">
      <v-card>
        <v-toolbar color="primary">
          <v-toolbar-title>
            <h3>מסכים</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field hide-details v-model="search" filled dense prepend-icon="mdi-magnify" color="white" outlined
            class="search-box mx-2"></v-text-field>
          <v-spacer></v-spacer>
          <tooltip-btn @action="showQuestionHandler = true" color="dark_blue" class="mx-2"
            :tooltipText="$t('questions.importQuestionTooltip')" :btnText="$t('questions.importQuestions')"
            icon="mdi-plus" outlined textClass="text-h6 font-weight-bold" />
          <tooltip-btn @action="add" :tooltipText="$t('questions.addQuestionTooltip')"
            :btnText="$t('questions.addQuestionBtn')" outlined color="dark_blue" icon="mdi-plus"
            textClass="text-h6 font-weight-bold" />
        </v-toolbar>
        <v-list subheader two-line class="slides-list overflow-x-hidden" max-height="700" v-if="questions.length">
          <draggable class="list-group" v-model="questions" tag="ul" v-bind="dragOptions" @start="drag = true"
            @end="drag = true">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <v-list-item v-for="(question, i) in questionsFilter" :key="question._id" class="list-group-item">
                <question-line :que="question" :index="i" :key="question._id" :clear="clearCurrentQuestion"
                  @clear="clearCurrentQuestion" @edit="edit" />
              </v-list-item>
            </transition-group>
          </draggable>
        </v-list>
        <import-questions-handler :title="$t('questions.setting')" v-model="showQuestionHandler"
          v-if="showQuestionHandler" />
      </v-card>
    </v-col>
    <v-col lg="6">
      <v-card>
        <v-toolbar color="primary">
          <v-row>
            <v-col class="d-flex align-center">
              <v-toolbar-title>
                <h3 v-text="
                  currentIndex
                    ? ` ${$t('games.editingQuestion')}  ${currentIndex}`
                    : $t('games.questionEditor')
                " />
              </v-toolbar-title>
            </v-col>
            <v-col v-if="currentQuestion" cols="1" class="d-flex justify-center align-center">
              <tooltip-icon class="mx-10" icon="mdi-cog-outline" :tooltipText="$t('setting.specificSettings')"
                color="black" @action="openSettingModel" />
            </v-col>
          </v-row>
        </v-toolbar>
        <slide-main :key="currentQuestion ? currentQuestion._id : 'new'" :question="currentQuestion" :isNew="isNew"
          :showSettings="showSettings" @closeSettingModel="closeSettingModel"
          @clearCurrentQuestion="clearCurrentQuestion" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable"
import TooltipBtn from "@/components/buttons/TooltipBtn"
import SwalMixins from "@/mixins/swal"
import ImportQuestionsHandler from "./handler/ImportQuestionsHandler.vue"
import SlideMain from "./SlideMain.vue"
import TooltipIcon from "@/components/buttons/TooltipIcon.vue"
import QuestionLine from "@/components/QuestionLine.vue"

export default {
  name: "SlidesList",
  components: {
    draggable,
    TooltipBtn,
    ImportQuestionsHandler,
    SlideMain,
    TooltipIcon,
    QuestionLine
  },
  mixins: [SwalMixins],
  data() {
    return {
      isNew: true,
      drag: true,
      search: "",
      showQuestionHandler: false,
      showQuestion: false,
      showSettings: false,
      currentQuestion: null,
      currentIndex: null,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      }
    },
    game() {
      return this.$store.getters.game
    },
    questions: {
      get() {
        if (!this.game) return []
        return this.$store.getters.game.questions
      },
      async set(newValue) {
        await this.$store.dispatch("game/question/update", {
          ...this.game,
          questions: [...newValue],
        })
        this.setIndex()
        return await this.$store.getters.game.questions
      },
    },
    questionsFilter() {
      if (!this.questions) return []
      return this.questions.filter((q) => {
        if (this.search) {
          return q.question.que.includes(this.search)
        }
        return true
      })
    },
  },
  methods: {
    openSettingModel() {
      this.showSettings = true
    },
    closeSettingModel() {
      this.showSettings = false
    },
    edit({ question, index }) {
      this.isNew = false
      this.currentIndex = index
      this.currentQuestion = question
    },
    add() {
      this.currentQuestion = {}
      this.isNew = true
      this.currentIndex = this.game.questions.length + 1
    },
    async duplicate(question) {
      await this.$store.dispatch("question/replicate", question._id)
    },
    setIndex() {
      if (this.currentQuestion && this.currentQuestion._id)
        this.currentIndex =
          this.game.questions.findIndex(
            (q) => q._id === this.currentQuestion._id
          ) + 1
    },
    clearCurrentQuestion() {
      this.currentQuestion = null
      this.currentIndex = null
    },
  },
}
</script>
