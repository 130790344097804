var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":this.fromImportQuestionsPage,"headers":_vm.isAdmin ? _vm.headers.concat( _vm.adminHeaders) : _vm.headers,"items":_vm.questionsFilter,"search":_vm.search,"loading":_vm.loading,"items-per-page":this.fromImportQuestionsPage ? 5 : 15,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"_id","show-expand":_vm.isAdmin,"footer-props":{ 'items-per-page-options': [25, 50, 100, 200, 250] }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"mx-5 mt-6"},[_c('v-autocomplete',{attrs:{"auto-select-first":"","chips":"","clearable":"","deletable-chips":"","multiple":"","small-chips":"","item-text":"name","label":_vm.$t('generics.searchByCategories'),"items":_vm.categories},model:{value:(_vm.searchByCategories),callback:function ($$v) {_vm.searchByCategories=$$v},expression:"searchByCategories"}})],1),_c('v-spacer'),_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('categories.editLabelsTooltip'),"icon":'mdi-pencil',"fab":true,"color":"primary"},on:{"action":function($event){_vm.editCategories = true}}}),_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex justify-start info--text ma-1"},[_vm._v(" "+_vm._s(_vm.$t("answers.answers"))+": ")])]),_vm._l((item.question.answers),function(ans,i){return _c('v-col',{key:ans._id,staticClass:"d-flex",attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(i + 1)+". "),_c('div',{class:(" " + (ans['correct'] ? 'success' : 'error') + "--text answers")},[_vm._v(" "+_vm._s(ans["ans"])+" ")])])})],2)],1)]}},{key:"item.categories",fn:function(ref){
                var item = ref.item;
return [_c('v-chip-group',{attrs:{"column":""}},_vm._l((item.categories),function(category){return _c('v-chip',{key:category._id},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)]}},{key:"item.user",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmailUser(item.user))+" ")]}},{key:"item.createdAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('tooltip-icon',{attrs:{"icon":"mdi-eye","color":item.displayRegularUser ? 'green' : 'red',"tooltipText":_vm.$t("questions.showAndDoNotShowToRegularUserTooltip"),"validator":""},on:{"action":function (disabled) { return _vm.saveShow(item, disabled); }}}),_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('questions.editQuestionTooltip'),"icon":'mdi-pencil',"fab":true},on:{"action":function($event){return _vm.editItem(item)}}}),_c('tooltip-icon',{attrs:{"tooltipText":_vm.$t('questions.deleteQuestionTooltip'),"icon":'mdi-delete',"color":"red","fab":true,"validator":""},on:{"action":function (disabled) { return _vm.deleteItem(item, disabled); }}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.editDialog)?_c('question-category-handler',{attrs:{"question":_vm.current},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}):_vm._e(),(_vm.editCategories)?_c('categories-handler',{on:{"loadData":_vm.loadDataAdmin},model:{value:(_vm.editCategories),callback:function ($$v) {_vm.editCategories=$$v},expression:"editCategories"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }