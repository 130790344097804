<template>
  <div>
    <!-- <v-container> -->
    <v-card max-width="1200" class="container mt-16">
      <v-card-title class="d-flex align-center">
        <v-row>
          <v-col cols="11">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              icon
              :label="$t('generics.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="center-all">
            <v-icon
              color="primary"
              @click="addDialog = true"
              v-text="'mdi-plus'"
            />
            <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
          <!-- {{ item }} -->
          <span v-if="item.role < 2" class="error--text">{{
            $t("generics.administration")
          }}</span>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="item.role >= 2"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteItem(item)" v-if="item.role >= 2"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
    <!-- </v-container> -->
    <user-handler v-if="addDialog" v-model="addDialog" :isNew="true" />
    <user-handler v-if="editDialog" v-model="editDialog" :user="current" />
  </div>
</template>

<script>
import userHandler from "@/components/handler/UserHandler.vue";
import SwalMixins from "@/mixins/swal";
export default {
  name: "usersTable",
  components: { userHandler },
  mixins: [SwalMixins],
  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t("auth.email"), value: "email" },
        { text: this.$t("auth.fullname"), value: "fullname" },
        { text: this.$t("auth.phone"), value: "phone" },
        { text: this.$t("generics.actions"), value: "actions", width: "10%" },
      ],
      current: null,
      search: "",
      addDialog: false,
      editDialog: false,
    };
  },
  computed: {
    users() {
      return this.$store.getters.users;
    },
  },
  methods: {
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("user/index");
      this.loading = false;
    },
    editItem(item) {
      this.current = item;
      this.editDialog = true;
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.createSwal({
        icon: "warning",
        title: `${this.$t("generics.delete")} ${item.email}?`,
      });
      if (isConfirmed) {
        await this.$store.dispatch("user/destroy", item._id);
      }
    },
  },
  async mounted() {
    this.$store.commit("title/set", this.$t(`route.${this.$route.name}`));
    if (!this.users.length) {
      await this.loadData();
    }
  },
};
</script>
