<template>
  <v-container class="progress">
    <div class="d-flex justify-center align-center">
      <v-progress-circular
        class="center-all"
        v-if="value"
        :rotate="360"
        :size="150"
        :width="15"
        :value="value"
        :color="progressColor"
      >
        {{ Math.ceil(value) }}%
      </v-progress-circular>
      <v-progress-circular
        v-else
        class="center-all"
        :size="150"
        :width="15"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <h1 class="d-flex justify-center align-center mt-10">
      {{ messages }}
    </h1>
  </v-container>
</template>

<script>
import SwalMixins from "@/mixins/swal";
export default {
  name: "LoadingPage",
  mixins: [SwalMixins],
  computed: {
    messages() {
      return this.$store.getters.loading.messages;
    },
    value() {
      return this.$store.getters.loading.value;
    },
    progressColor() {
      if (this.value < 30) {
        return "red";
      } else if (this.value < 60) {
        return "pink";
      } else if (this.value < 90) {
        return "yellow";
      } else {
        return "light-green accent-3";
      }
    },
  },
};
</script>
