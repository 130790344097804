<template>
  <my-dialog
    :title="`${$t('questions.importQuestions')}: בחרת ${selected.length} שאלות`"
    v-model="openModal"
    :fillHeight="false"
  >
    <template v-slot:content>
      <all-questions :fromImportQuestionsPage="true" @onSelect="select" />
    </template>
    <template v-slot:actions>
      <v-btn
        :disabled="loading"
        color="primary"
        v-text="$t('generics.import')"
        @click="duplicate"
      />
    </template>
  </my-dialog>
</template>

<script>
import SwalMixins from "@/mixins/swal";
import GenericMixins from "@/mixins/generic";
import MyDialog from "../MyDialog.vue";
import AllQuestions from "@/views/Admin/AllQuestions.vue";

export default {
  name: "import-questions-handler",
  components: { MyDialog, AllQuestions },
  mixins: [SwalMixins, GenericMixins],
  props: {
    value: Boolean,
  },
  data() {
    return {
      selected: [],
      singleExpand: true,
      loading: false,
      search: "",
    };
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    game() {
      return this.$store.getters.game;
    },
  },
  methods: {
    select(selected) {
      this.selected = [...selected];
    },
    async loadData() {
      await this.$store.dispatch("question/showMany");
      await this.$store.dispatch("category/index");
    },
    async loadAdminData() {
      await this.$store.dispatch("question/index");
      await this.$store.dispatch("category/index");
    },
    async duplicate() {
      try {
        this.loading = true;
        await this.$store.dispatch("question/storeMany", {
          questions: this.selected,
          game: this.game._id,
        });
        this.openModal = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
  async mounted() {
    this.loading = true;

    if (this.isAdmin) {
      this.loadAdminData();
    } else {
      this.loadData();
    }
    this.loading = false;
  },
};
</script>
