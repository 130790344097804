<template>
  <v-container
    class="d-flex align-center flex-column center-all mb-10"
    fill-height
  >
    <div class="d-flex align-center flex-column center-all">
      <v-row md="6" sm="center-all">
        <v-col md="6" sm="12"
          ><v-btn
            class="center-all ma-10"
            :to="{ name: 'MyGame' }"
            :min-width="maxWidth"
            min-height="100"
            v-text="$t('route.MyGame')"
        /></v-col>
        <v-col md="6" sm="12"
          ><v-btn
            class="center-all ma-10"
            :to="{ name: 'AllGames' }"
            :min-width="maxWidth"
            min-height="100"
            v-text="$t('route.AllGames')"
        /></v-col>
      </v-row>
      <v-row>
        <v-col md="6" sm="12"
          ><v-btn
            class="center-all ma-10"
            :to="{ name: 'AllQuestions' }"
            :min-width="maxWidth"
            min-height="100"
            v-text="$t('route.AllQuestions')"
        /></v-col>
        <v-col md="6" sm="12"
          ><v-btn
            class="center-all ma-10"
            :to="{ name: 'AllUsers' }"
            :min-width="maxWidth"
            min-height="100"
            v-text="$t('route.AllUsers')"
        /></v-col>
      </v-row>
      <v-row md="6" sm="center-all">
        <v-col md="6" sm="12"
          ><v-btn
            :class="`center-all ma-10`"
            @click="goGamePage"
            :min-width="maxWidth"
            min-height="100"
            v-text="$t('route.CreateGame')"
        /></v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SwalMixins from "@/mixins/swal";

export default {
  name: "Adminroute",
  mixins: [SwalMixins],
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    maxWidth() {
      return this.breakpointName === "xs" ? "200" : "400";
    },
  },
  methods: {
    async goGamePage() {
      try {
        const { isConfirmed, value } = await this.createInputSwaldata({
          title: this.$t("games.name"),
        });
        if (!isConfirmed) return;
        this.$store.commit("loading/active", true);
        await this.$store.dispatch("game/store", { name: value });
        await this.$router.push({
          name: "CreateGame",
          params: { isNew: true, id: this.$store.getters.game._id },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.$store.commit("title/set", this.$t(`route.${this.$route.name}`));
  },
};
</script>
