<template>
  <v-dialog ref="dialog" v-model="modal" width="500px">
    <template v-slot:activator="{ on }">
      <label for="colorPicker">
        {{ label }}

        <div
          class="circle-button pa-3"
          v-bind="$attrs"
          :color="color"
          v-on="on"
          id="colorPicker"
        >
          <span :style="`background:${color}`"></span>
        </div>
      </label>
    </template>
    <v-color-picker
      v-model="color"
      width="500px"
      class="mx-auto"
      :swatches="swatches"
      show-swatches
      hide-inputs
    />
  </v-dialog>
</template>

<script>
export default {
  name: "my-color-picker",
  props: {
    value: String,
    label: { type: String, default: "" },
  },
  data() {
    return {
      modal: false,
      swatches: [
        ["#FF0000ff", "#AA0000ff", "#550000ff"],
        ["#f9d673ff", "#AAAA00ff", "#555500ff"],
        ["#00FF00ff", "#00AA00ff", "#005500ff"],
        ["#00FFFFff", "#00AAAAff", "#005555ff"],
        ["#0000FFff", "#0000AAff", "#000055ff"],
      ],
      transparent: "#00000000",
    };
  },
  computed: {
    color: {
      get() {
        return this.value ? this.value : "#808080ff";
      },
      set(val) {
        console.log(val);
        this.$emit("input", val);
      },
    },
  },
};
</script>
<style scoped>
.circle-button span {
  display: block;
  width: 20px;
  height: 20px;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  /* transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%); */
  transition: ease 0.3s;
}
</style>
