<template>
  <div>
    <my-dialog :title="`${$t('setting.payPage')}`" v-model="openModal">
      <template v-slot:content>
        <v-row class="ma-5" v-model="radioGroupUrl">
          <v-col cols="6">
            <v-row class="d-flex justify-start">
              {{ $t("הפעלה בטלפונים:") }}
            </v-row>
            <v-radio-group class="mt-10" v-model="radioGroupUrl">
              <v-row v-for="pay in payListPhones" :key="pay.link">
                <v-col cols="12">
                  <v-radio :label="`${pay.label}`" :value="pay"></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-row class="d-flex justify-start">
              {{ $t("הפעלה בקליקרים:") }}
            </v-row>
            <v-radio-group class="mt-10" v-model="radioGroupUrl">
              <v-row v-for="pay in payListClickers" :key="pay.link">
                <v-col cols="12">
                  <v-radio :label="`${pay.label}`" :value="pay"></v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="err">
          <v-col class="error--text text-center">
            {{ err }}
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn color="primary" v-text="$t('generics.toPay')" @click="goToPay" />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/MyDialog.vue";
export default {
  name: "out-to-pay-handler",
  props: {
    value: Boolean,
  },
  components: { MyDialog },
  data: () => ({
    baseUrl: "http://pay.sumit.co.il/1t6qve/1y078z/",
    radioGroupUrl: {
      limitCode: "p1",
      label: "עד 50",
      link: "1y0797",
    },
    payListPhones: [
      {
        limitCode: "p1",
        label: "עד 50",
        link: "1y0797",
      },
      {
        limitCode: "p2",
        label: "עד 100",
        link: "1ybijy",
      },
      {
        limitCode: "p3",
        label: "עד 200",
        link: "1yidjm",
      },
      {
        limitCode: "p4",
        label: "עד 300",
        link: "1yifsa",
      },
      {
        limitCode: "p5",
        label: "עד 500",
        link: "1yidk7",
      },
    ],
    payListClickers: [
      {
        limitCode: "c1",
        label: "עד 50",
        link: "1yidka",
      },
      {
        limitCode: "c2",
        label: "עד 100",
        link: "1yidli",
      },
      {
        limitCode: "c3",
        label: "עד 150",
        link: "1yidlk",
      },
      {
        limitCode: "c4",
        label: "עד 200",
        link: "1yidlu",
      },
      {
        limitCode: "c5",
        label: "עד 250",
        link: "1yidzo",
      },
      {
        limitCode: "c6",
        label: "עד 300",
        link: "1yienb",
      },
    ],
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    gameId() {
      return this.$store.getters.game._id;
    },
    uid() {
      return this.$store.getters.uid;
    },
  },
  methods: {
    goToPay() {
      window.location.href = `${this.baseUrl}${this.radioGroupUrl.link}/payment/?externalidentifier=${this.uid}:${this.gameId}:${this.radioGroupUrl.limitCode}`;
    },
  },
};
</script>
