<template>
  <div
    class="
      d-flex
      justify-center
      align-center
      mouse-pointer
      pa-2
      v-btn
      white--text
      rounded-lg
    "
  >
    <v-col class="primary">
      <span> {{ $t("auth.loginByGoogle") }} </span>
    </v-col>
    <v-img
      lazy-src="/assets/btn_google_light_focus.svg"
      max-width="60"
      src="/assets/btn_google_light_focus.svg"
      class="mx-0 google-btn"
    ></v-img>
  </div>
</template>

<script>
export default {
  name: "GoogleBtn",
};
</script>

<style>
.google-btn {
  border: 1px solid var(--primary);
}
</style>
