import axios from "axios";
import swalMixins from "../../mixins/swal";
import genericMixins from "../../mixins/generic";

export default {
  actions: {
    //destroys one image
    "image/destroy": async (context, { public_id, type }) => {
      try {
        await axios.post("/upload/image", { public_id, type });
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: "error",
          title: genericMixins.methods.extractClapErrorEn(e),
        });
      }
    },

    // //stores one upload
    // "upload/image": async (context, payload) => {
    //   try {
    //     // const axiosConfig = {
    //     //   onUploadProgress: (e) => {
    //     //     let total = e.total;
    //     //     let loaded = e.loaded;
    //     //     this.progress = parseInt((100 / total) * loaded);
    //     //   },
    //     //   defaultPrevented: true,
    //     // };
    //     let formdata = new FormData();
    //     formdata.append("file", payload.file);
    //     formdata.append("upload_preset", payload.uploadPreset);
    //     // let { data } = await axios.post("/upload/image", { ...payload });
    //     return;
    //   } catch (e) {
    //     swalMixins.methods.timeoutSwal({
    //       icon: "error",
    //       title: genericMixins.methods.extractClapErrorHe(e),
    //     });
    //   }
    // },
    //destroys one upload
    "upload/xlsx": async (context, { file, gameId }) => {
      try {
        let fd = new FormData();
        fd.append("file", file);
        await axios.post("/upload/xlsx/" + gameId, fd);
        await context.dispatch("game/show", gameId);
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: "error",
          title: genericMixins.methods.extractClapErrorHe(e),
        });
      }
    },
    //destroys one upload
    "upload/export-game": async (context, id) => {
      try {
        const { data } = await axios.get("/game/export-game/" + id);
        return data;
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: "error",
          title: genericMixins.methods.extractClapErrorHe(e),
        });
      }
    },
    //create json in cloudinary folder
    "upload/create-json-in-cloudinary": async (context, id) => {
      try {
        const { data } = await axios.get("/upload/create-json/" + id);
        return data;
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: "error",
          title: genericMixins.methods.extractClapErrorHe(e),
        });
      }
    },
  },
};
