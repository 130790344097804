const he = {
  appTitle: "מחולל השאלות",
  generics: {
    room: "חדר",
    uploadFile: "העלאת קבצים",
    youtubeUpload: "העלאה מיוטיוב",
    mediaUpload: "העלאת מדיה",
    import: "ייבא",
    administration: "מנהל",
    action: "פעולות",
    dragHere: "גרור את הקבצים לפה",
    click: "תלחץ על הכפתור",
    or: "או",
    the: "את",
    home: "בית",
    mainMenu: "תפריט ראשי",
    change: "שנה",
    actions: "פעולות",
    delete: "למחוק",
    remove: "מחק",
    name: "שם",
    add: "הוסף",
    save: "שמור",
    edit: "ערוך",
    search: "חיפוש",
    searchByCategories: "חיפוש על פי קטגוריה",
    new: "חדש",
    ok: "אישור",
    cancel: "ביטול",
    confirm: "אישור",
    users: "משתמשים",
    selectColors: "בחר צבע",
    color: "צבע",
    send: "שלח",
    title: "כותרת",
    message: "הודעה",
    urgent: "דחוף",
    regular: "רגיל",
    complex: "מורכב",
    createdAt: "נוצר בתאריך",
    showLink: "קישור למשחק",
    toPay: "לתשלום",
    numbers: "מספרים",
    words: "אותיות",
  },
  route: {
    MyGame: "המשחקים שיצרתי",
    AllGames: "כל השעשועונים שהונפקו עד היום",
    AllQuestions: "כל השקופיות",
    Management: "מחולל הסיסמאות וניהול משתמשים",
    CreateGame: "יצירת משחק",
    AllUsers: "מחולל הסיסמאות וניהול משתמשים",
    UserDashboard: "מחולל השאלות",
    AdminDashboard: "מחולל השאלות - ניהול",
    Login: "כניסה",
  },
  days: {
    sunday: "יום ראשון",
    monday: "יום שני",
    tuesday: "יום שלישי",
    wednesday: "יום רביעי",
    thursday: "יום חמישי",
    friday: "יום שישי",
    saturday: "שבת",
    all: "כל הימים",
  },
  daysKeys: {
    sunday: "א",
    monday: "ב",
    tuesday: "ג",
    wednesday: "ד",
    thursday: "ה",
    friday: "ו",
    saturday: "ז",
  },
  date: {
    month: "חודש",
    week: "שבוע",
    day: "יום",
    today: "היום",
    dateStart: "תאריך התחלה",
    dateEnd: "תאריך סיום",
    creationDate: "תאריך יצירה",
    deletionDate: "תאריך מחיקה",
    timeStart: "זמן התחלה",
    timeEnd: "זמן סיום",
    name: {
      January: "ינואר",
      February: "פברואר",
      March: "מרץ",
      April: "אפריל",
      May: "מאי",
      June: "יוני",
      July: "יולי",
      August: "אוגוסט",
      September: "ספטמבר",
      October: "אוקטובר",
      November: "נובמבר",
      December: "דצמבר",
    },
  },

  auth: {
    phone: "מספר טלפון",
    fullname: "שם מלא",
    email: "אימייל",
    password: "סיסמה",
    login: "התחברות",
    logout: "להתנתק",
    change: "לשנות פרטי משתמש",
    registerSuccess: "נרשמת בהצלחה! \n \nאנא לחץ על התחבר",
    updateSuccess: "עודכן בהצלחה!",
    loginByGoogle: "כניסה עם גוגל",
  },

  userDetails: {
    userDetails: "פרטים אישיים",
    name: "שם",
    phone: "טלפון",
    email: "אימייל",
    address: "כתובת",
    addNewUser: "הוסף משתמש חדש",
    editUserDetails: "ערוך פרטי משתמש",
  },
  errors: {
    validate: {
      required: "שדה חובה",
      minlen6: "מינימום 6 תוים",
      minlen2: "מינימום 2 תוים",
      maxlen40: "מקסימום 40 תוים",
      validNumber: "אנא הכנס מספר תקין",
    },
    auth: {
      email: "יש להזין אמייל",
      emailInvalid: "אימייל שגוי",
      pass: "יש להזין סיסמה",
      minlen6: "מינימום 6 תוים",
    },
    notEmpty: "לא יכול להיות ריק",
    global: "אירעה שגיאה בלתי צפויה",
    globalShort: "שגיאה",
    missingItems: "פרטים חסרים",
    format: "הפורמטים שאפשר לעלות הם:",
    tildeUpload: "אין לעלות קובץ עם הסימון ~"
  },
  setting: {
    gameName: "שם המשחק",
    setting: "הגדרות",
    clickHereTo: "לחץ כאן ל{prop}",
    saveGame: "לחץ כאן לשמירת המשחק",
    clickHereToExpotFromXl: "לחץ כאן לייבא שאלות מקובץ אקסל",
    importFromXl: "ייבוא מאקסל",
    export: "ייצוא משחק",
    textAllGame: "כיתוב שיופיעו לכל אורך המשחק",
    maxParticipants: "מקסימום משתתפים",
    image: "תמונת רקע",
    logo: "לוגו",
    limitNumberParticipants: "הגבל את מספר המשתתפים",
    specificSettings: "הגדרות השקופית",
    showWinnersListAfter: "הצג טבלת תוצאות אחרי",
    cancelWinnersList: " בטל הצגת טבלת תוצאות אחרי",
    triviaMedia: "מדיה לטריוויה",
    winnersListMedia: "מדיה לרשימת המובילים",
    winnersMedia: "מדיה למנצח/ים",
    demoGame: "משחק לדוגמה",
    payPage: "עמוד תשלום",
    ansIsNumber: "התשובות שיופיע על המסך הם מספרים או אותיות",
    multiWinners: "כמות המנצחים",
    winnersMediaSound: "סאונד של המנצחים",
    winnersListMediaSound: "סאונד של רשימת המובילים",
    genericMediaSound: "סאונד כללי",
    timerMediaSound: "סאונד טיימרים",
    inShowAnsMediaSound: "סאונד כשמראים את התשובה",
  },
  images: {
    save: "המדיה נשמרה בהצלחה",
    saveError: "הייתה שגיאה בהעלעת המדיה",
    delete: "המדיה נמחקה בהצלחה",
    deleteError: "הייתה שגיאה במחיקת התמונה",
    errorSize: "תמונה גדולה מ",
  },
  games: {
    save: "המשחק נשמר בהצלחה",
    delete: "למחוק את המשחק",
    name: "שם המשחק",
    addAns: "הוסף תשובה + ",
    answers: "תשובות",
    correctAns: "תשובה נכונה",
    scoreForQue: "נקודות לשאלה",
    timeForQue: "זמן השאלה (שניות)",
    que: "שאלה",
    media: "מדיה",
    openMedia: "מדיה פתיחה",
    endMedia: "מדיה סיום",
    backgroundMedia: "תמונה ברקע",
    editingQuestion: "עריכת שאלה",
    questionEditor: "עורך השאלות",
    thereMedia: "יש מדיה",
    duplicateGameTooltip: "לחץ לשכפול המשחק",
    editGameTooltip: "לחץ עריכת המשחק",
    deleteGameTooltip: "לחץ למחיקת המשחק",
    setting: {
      mainColor: "צבע ראשי",
      secondaryColor: "צבע משני",
    },
  },
  questions: {
    questions: "שאלות",
    sum: "סך כל השאלות",
    save: "שמירת מסך בוצעה בהצלחה",
    saveError: "שגיאה בשמירת המסך",
    delete: "האם אתה בטוח שברצונך למחוק את השאלה",
    addQuestionTooltip: "לחץ כאן כדי להוסיף שאלה / מדיה",
    addQuestionBtn: " שאלה חדשה",
    importQuestionTooltip: "לחץ כאן כדי לייבא שאלה מהמאגר",
    importQuestionBtn: "ייבוא שאלה",
    importQuestions: "ייבוא שאלות",
    duplicateQuestionTooltip: "לחץ לשכפול השאלה",
    editQuestionTooltip: "לחץ עריכת השאלה",
    deleteQuestionTooltip: "לחץ למחיקת השאלה",
    showAndDoNotShowToRegularUserTooltip: "תציג/אל תציג את השקופית למשתמש רגיל",
    selectType: "בחר סוג שאלה",
    setting: "הגדרות שאלה",
    createByUser: "נוצר על ידי",
    questionLabeling: "תייג את השאלה",
    labels: "קטגוריות",
    slidBackgroundMedia: "מדיה רקע לשקופית",
    categories: "קטגוריות",
    mediaTitle: "שם שקופית המדיה",
  },
  answers: {
    answers: "תשובות",
  },
  categories: {
    editLabelsTooltip: "לחץ לעריכת הקטגוריות",
    delete: "האם אתה בטוח שברצונך למחוק את הקטגוריה",
    add: "הוסף קטגוריה",
  },
};
export default he;
