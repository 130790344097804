module.exports = [
  'adminModule',
  'authModule',
  'gameModule',
  'questionModule',
  'userModule',
  'uploadModule',
  'settingModule',
  'categoryModule',
  'gameResultModule',
]
