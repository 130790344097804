<template>
  <div>
    <my-dialog
      :title="$t('questions.questionLabeling') + ':\n' + question.question.que"
      v-model="openModal"
    >
      <template v-slot:content>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="chips"
              deletable-chips
              multiple
              small-chips
              :label="$t('generics.searchByCategories')"
              :items="items"
              chips
              return-object
              item-text="name"
              solo
              @input="select"
              @keydown.enter="create"
              ref="select"
              clearable
            />
          </v-col>
        </v-row>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/MyDialog.vue";
export default {
  name: "question-category-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    question: Object,
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    chips: [],
    form: { name: "" },
    err: null,
  }),
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    items() {
      return this.$store.getters.categories;
    },
  },
  methods: {
    async select() {
      try {
        this.question.categories = this.chips;
        // .map((l) => l.name);
        await this.$store.dispatch("question/update/category", this.question);
        this.$refs.select.lazySearch = "";
      } catch (e) {
        console.log(e);
      }
    },
    async create({ target }) {
      try {
        console.log(target.value.trim().length);
        if (target.value.trim().length) {
          const category = await this.$store.dispatch("category/store", {
            name: target.value,
          });
          this.chips = [...this.chips, category];
          await this.select();
          await this.$store.dispatch("question/update/category", this.question);
        }
        this.$refs.select.lazySearch = "";
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.chips = this.question.categories
      ? this.items.filter((category) => {
          let result;
          this.question.categories.forEach((c) => {
            if (category.name === c.name) return (result = true);
          });
          return result;
        })
      : [];
  },
};
</script>
